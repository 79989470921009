import React, { useEffect, useState } from "react";
import { MdUpload } from "react-icons/md";

import "./documentUpload.scss";
import { errorView } from "../../helpers/ErrorHandler";

export const DocumentUploader = ({
  uploadButtonView = true,
  customFileView,
  buttonTitle = "Add Image",
  imageData,
  customImageUploadMain,
  mainTitle = null,
  maxFileSize = null,
}: any) => {
  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (!file) return;
    if (maxFileSize) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > maxFileSize) {
        errorView(
          `Max file size is ${maxFileSize} MB. Your file is ${fileSizeInMB.toFixed(
            2
          )} MB.`
        );
        return;
      }
    }
    imageData(file);
  }
  return (
    <div className={`documentUploaderMain ${customImageUploadMain}`}>
      <p className="labelUploder">{mainTitle}</p>
      {uploadButtonView ? (
        <label className={`${customFileView} custom-document-upload`}>
          <input type="file" onChange={handleFileChange} />
          <MdUpload size={18} className="image" />
          {buttonTitle}
        </label>
      ) : null}
    </div>
  );
};
