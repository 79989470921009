import { useContext, useState, useMemo, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import { DrawingContext } from "../../../SimulateDrawingTool";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import { SimulationChemicalContent } from "../../../../../components";
import { SipContents } from "../../../../../config/constants";
import AddIngredientModal from "../../../../cultivation/processing/IngredientContentModal";
import SimulationSipContentContent from "../../../../cultivation/processing/SimulationSipContentModal";
import SimulationRecipeContent from "../../../../cultivation/processing/SimulationRecipeModal";
import {
  useGetSimulationProcessGateProcessDataById,
  usePostSimulationProcessGateProcessData,
  usePutSimulationProcessGateProcessData,
  useDeleteSimulationProcessGateProcessData,
} from "../../../../../api/simulation/processGateProcess";
import {
  useGetFacilityWholeList,
  useGetProcessTypesWholeList,
  useGetTransportTypesList,
} from "../../../../../api/cultivations";
import { errorView, successMessage } from "../../../../../helpers/ErrorHandler";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import {
  useGetChemicalTypes,
  useGetWasteManagementWasteTypes,
} from "../../../../../api/static-data";
import { SimulationWasteManagementContent } from "../../../../../components/wasteManagment/SimulationWasteManagementModal";
import {
  removeNewDrawingItemStatus,
  saveNewDrawingItem,
} from "../../../../../utils/cacheStorage";
import SimulationProcessingDetails from "../../../../../components/processingDetails/SimulationProcessingDetails";
import SimulationMeasurementDetails from "../../../../../components/measurementDetails/SimulationMeasurementDetails";
import { isArray } from "lodash";
import { useGetProcessGateProcessDataById } from "../../../../../api/process";
import {
  AccordionNames,
  validateAccordionOpen,
  validateAccordionSubmit,
} from "../../../../../helpers/AccordionHelper";

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

interface ProcessFormData {
  processingName: string;
  processingType: number;
  facilityName: string;
  machineModelNo: string;
  capacity: number;
  input: number;
  output: number;
  sideStream: number;
  heat: number;
  electricity: number;
  water: number;
  citricAcid?: number;
}

export const ProcessGateProcessingBase = ({ modalData }: any) => {
  const {
    processGateId,
    productId,
    salesUnitId,
    nodeItem,
    ingredientId,
    supplierId,
  } = modalData;
  const { deleteElements } = useReactFlow();
  const processGateReference = nodeItem?.data?.reference;

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);

  const { height } = useWindowDimensions();
  const processId = nodeItem?.data?.reference?.processId;
  const [viewIngredient, setViewIngredient] = useState(false);
  const [facilityName, setFacilityName] = useState("");
  const [processingType, setProcessingType] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [bounceEnable, setBounceEnable] = useState(false);
  const [accordionStatus, setAccordionStatus] = useState<boolean>(false);
  const [accordionType, setAccordionType] = useState<AccordionNames | null>(
    null
  );
  const { data: facilityWholeList, isSuccess: isFacilitySuccess } =
    useGetFacilityWholeList("FACTORY");
  const { data: processTypesWholeList } = useGetProcessTypesWholeList();
  const { mutate: postFarmGateProcessData } =
    usePostSimulationProcessGateProcessData();
  const { mutate: putFarmGateProcessData } =
    usePutSimulationProcessGateProcessData();
  const { mutate: deleteProcessGateProcessData } =
    useDeleteSimulationProcessGateProcessData();
  const customCapacityValidation = useRef<HandleValidation>(null);
  const customInputValidation = useRef<HandleValidation>(null);
  const customOutputValidation = useRef<HandleValidation>(null);
  const { data, status, fetchStatus, refetch } =
    useGetSimulationProcessGateProcessDataById(
      nodeItem?.data?.reference?.processId
    );

  const {
    data: originalProcessData,
    status: originalProcessStatus,
    fetchStatus: originalProcessFetch,
  } = useGetProcessGateProcessDataById(data?.originalProcessingId || null);

  const [cipSipData, setCipSipData] = useState<any>([]);
  const [addIngredientActive, setAddIngredientActive] = useState(false);

  const handleChemical = () => {
    if (processId) {
      return true;
    } else {
      setBounceEnable(true);
      errorView("Please save the process to add chemical details");
      return false;
    }
  };

  const checkRecipeValidation = () => {
    setBounceEnable(true);
    errorView("Please save the process to add recipe");
    return;
  };

  const handleWasteManagement = () => {
    if (processId) {
      return true;
    } else {
      setBounceEnable(true);
      errorView("Please save the process to add waste management details");
      return false;
    }
  };

  const values = useMemo(() => {
    setCipSipData(data?.cipSip);
    setProcessingType(`${data?.processingType}`);
    setFacilityName(data?.facilityId);
    return {
      processingName: data?.processingName,
      processingType: data?.processingType,
      facilityName: data?.facilityId,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity,
      input: data?.input,
      output: data?.output,
      sideStream: data?.sideStream,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid,
      recipeId: data?.recipeId,
    };
  }, [nodeItem, data]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });
  const chemicalData = data?.chemical || [];
  const wasteManagement = data?.wasteManagements || [];

  const { data: chemicalDataType } = useGetChemicalTypes();
  const { data: wasteManagementType } = useGetWasteManagementWasteTypes();
  const { data: TransportTypeList } = useGetTransportTypesList();

  useEffect(() => {
    if (isFacilitySuccess) {
      setFacilityName(data?.facilityId || facilityWholeList?.[0]?.value);
      setValue(
        "facilityName",
        data?.facilityId || facilityWholeList?.[0]?.value
      );
    }
  }, [isFacilitySuccess, data]);

  const handleAccordion = (
    activeStatus: boolean,
    requestType: AccordionNames
  ) => {
    if (!activeStatus) {
      setAccordionStatus(false);
      setAccordionType(null);
      return false;
    }
    if (validateAccordionOpen(accordionStatus, accordionType, requestType)) {
      setAccordionStatus(true);
      setAccordionType(requestType);
      return true;
    }
  };

  const handleFormSubmit = (data: ProcessFormData) => {
    if (!validateAccordionSubmit(accordionType)) {
      return;
    }
    onSubmit(data);
  };

  const onSubmit = (data: any, cipSip?: any) => {
    let checkIsValidCipSip = isArray(cipSip);
    let dataToCreate = {
      processingName: data?.processingName,
      productId: productId,
      salesUnitId: salesUnitId,
      ingredientId: ingredientId,
      supplierId: supplierId,
      processNumber: 1,
      facilityId: data?.facilityName,
      processingIngredient: [],
      cipSip: checkIsValidCipSip ? cipSip : cipSipData,
      processingType: data?.processingType,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity || 0,
      input: data?.input || 0,
      output: data?.output || 0,
      sideStream: data?.sideStream || 0,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid || 0,
      processingStatus: "ACTIVE",
      processGateId: processGateId,
      processId: nodeItem?.data?.reference?.processId,
      changeStatus: true
    };

    if (processGateReference) {
      dataToCreate.processId = processGateReference.processId;
      putFarmGateProcessData(dataToCreate, {
        onSuccess: (data: any) => {
          removeNewDrawingItemStatus();
          const updatedNode = {
            ...nodeItem,
            data: {
              ...nodeItem?.data,
              label: data?.processingName,
              // reference is mongoose mix type so you can set any type of here please set necessary reference only
              reference: {
                processId: data?._id,
              },
            },
          };
          const updatedChartNodes = chartNodes.map((n: any) => {
            if (n.id === nodeItem?.id) {
              return updatedNode;
            }
            return n;
          });
          if (checkIsValidCipSip) {
            successMessage("Process updated CIP / SIP successfully");
            return refetch();
          }
          setChartNodes(updatedChartNodes);
          setNodeItem(updatedNode);
          saveDrawing(chartEdges, updatedChartNodes);
          successMessage("Process updated successfully");
        },
      });
      return;
    }
    dataToCreate.processId = processGateId;
    postFarmGateProcessData(dataToCreate, {
      onSuccess: (data: any) => {
        saveNewDrawingItem("created");
        const updatedNode = {
          ...nodeItem,
          data: {
            ...nodeItem?.data,
            label: data?.processingName,
            // reference is mongoose mix type so you can set any type of here please set necessary reference only
            reference: {
              processId: data?._id,
            },
          },
        };
        const updatedChartNodes = chartNodes.map((n: any) => {
          if (n.id === nodeItem?.id) {
            return updatedNode;
          }
          return n;
        });
        setChartNodes(updatedChartNodes);
        setNodeItem(updatedNode);
        saveDrawing(chartEdges, updatedChartNodes);
        successMessage("Process created successfully");
      },
    });
  };

  const loadCIPData = (currentData: any[]) => {
    if (nodeItem?.data.reference && currentData?.length > 0) {
      const responseSipData = Object.keys(currentData?.[0]);
      const newSipContent: any = SipContents?.map((sip) => {
        const sipContent = responseSipData?.map((resSip) => {
          return (
            sip?.typeName == resSip && {
              ...sip,
              value: currentData?.[0][resSip],
            }
          );
        });
        return sipContent.filter((value) => value !== false);
      });
      return newSipContent.flat(Infinity);
    } else {
      return SipContents;
    }
  };

  const originalCipSipData = useMemo(
    () => loadCIPData(originalProcessData?.cipSip),
    [originalProcessData]
  );

  const simulationCipSipData = useMemo(() => loadCIPData(data?.cipSip), [data]);

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  if (
    (nodeItem?.data.reference &&
      status == "loading" &&
      fetchStatus == "fetching") ||
    (originalProcessStatus == "loading" && originalProcessFetch == "fetching")
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const updateCipSipData = (data: any) => {
    setCipSipData(data);
    const currentData = getValues();
    onSubmit(currentData, data);
  };

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div
              style={{
                height: height * 0.9 - 80,
                overflowX: "hidden",
              }}
            >
              <SimulationProcessingDetails
                register={register}
                errors={errors}
                processTypesWholeList={processTypesWholeList}
                processingType={processingType}
                setProcessingType={setProcessingType}
                setValue={setValue}
                facilityName={facilityName}
                isValidate={isValidate}
                facilityWholeList={facilityWholeList}
                setFacilityName={setFacilityName}
                originalProcessData={originalProcessData}
              />
              <SimulationMeasurementDetails
                control={control}
                errors={errors}
                customCapacityValidation={customCapacityValidation}
                customInputValidation={customInputValidation}
                customOutputValidation={customOutputValidation}
                addIngredientActive={addIngredientActive}
                setViewIngredient={setViewIngredient}
                data={data}
                originalProcessData={originalProcessData}
              />
              <Grid>
                <Grid.Column computer={16}>
                  <SimulationSipContentContent
                    getSipData={(data: any) => {
                      updateCipSipData(data);
                    }}
                    processCIPData={simulationCipSipData}
                    currentCipSipData={data?.cipSip}
                    originalCipSipData={originalProcessData?.cipSip}
                    originalProcessCIPData={originalCipSipData}
                    currentAccordionStatus={handleAccordion}
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <SimulationRecipeContent
                    processId={nodeItem?.data?.reference?.processId}
                    nodeItem={nodeItem}
                    recipeId={data?.recipeId}
                    checkRecipeValidation={checkRecipeValidation}
                    type="process-gate-simulation"
                    data={data}
                    originalProcessData={originalProcessData}
                    currentAccordionStatus={handleAccordion}
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <SimulationChemicalContent
                    chemicalDataType={chemicalDataType}
                    processCIPData={() => {}}
                    chemicalData={chemicalData}
                    processId={processId}
                    TransportTypeList={TransportTypeList}
                    refetchChemical={refetch}
                    handleOpenValidation={handleChemical}
                    type="process"
                    data={data}
                    originalProcessData={originalProcessData}
                    currentAccordionStatus={handleAccordion}
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <SimulationWasteManagementContent
                    wasteManagementType={wasteManagementType}
                    wasteManagement={wasteManagement}
                    processId={processId}
                    sideStreamValue={values?.sideStream}
                    refetchWasteData={refetch}
                    handleOpenValidation={handleWasteManagement}
                    type="process"
                    data={data}
                    originalProcessData={originalProcessData}
                    currentAccordionStatus={handleAccordion}
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              saveButton={() => {
                setBounceEnable(false);
                customCapacityValidation.current?.checkCustomRequired(
                  getValues().capacity
                );
                customInputValidation.current?.checkCustomRequired(
                  getValues().input
                );
                customOutputValidation.current?.checkCustomRequired(
                  getValues().output
                );
              }}
              deleteButton={() => setVisibleDeleteModal(true)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              bounceEnable={bounceEnable}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <AddIngredientModal
        visibleModal={viewIngredient}
        setVisibleModal={() => {
          setViewIngredient(false);
        }}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            processGateId: processGateId,
            processId: nodeItem?.data?.reference?.processId,
          };
          deleteProcessGateProcessData(dataToDelete, {
            onSuccess: (data: any) => {
              onNodesDelete();
              successMessage("Process deleted successfully");
            },
          });
          setVisibleDeleteModal(false);
        }}
        title={`Delete ${data?.processingName} Process`}
        subTitle={`Are you sure you want to remove the ${data?.processingName} process gate process? Please consider you cannot recover these data after remove`}
      />
    </Grid>
  );
};
