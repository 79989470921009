import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi, customConfig } from "../apiService";
import { errorView } from "../../helpers/ErrorHandler";
import { removeTokens } from "../../utils/cacheStorage";

interface commentData {
  _id: string;
  tenantId: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  parentId: string;
  internalNotes: string;
  reviewingNotes: string;
  supportingDocuments: any[];
}

export const useGetCommentDataById = (id: string | null) =>
  useQuery<commentData, ApiServiceErr>(
    ["getCommentData", id],
    async () => {
      const response = await axiosApi.get(`/comments/by-parentId/${id}`);
      return response?.data || null;
    },
    {
      cacheTime: 0,
      enabled: !!id,
      onError: () => {
        errorView("User hasn't any company");
        removeTokens();
      },
    }
  );

export const usePostCommentData = (data?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`comments`, data.data);
    return response?.data;
  }, data);

export const usePatchCommentData = (data?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.patch(`comments/${data.id}`, data.data);
    return response?.data?.data;
  }, data);

export const useUploadFileDocument = (data?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`upload-documents/upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    return response?.data;
  }, data);

export const useGetDocumentUrlByKey = (key: string | null) =>
  useQuery<any, ApiServiceErr>(
    ["getDocumentByKey", key],
    async () => {
      const response = await axiosApi.get(
        `/upload-documents/download?key=${key}`
      );
      return response?.data || null;
    },
    {
      cacheTime: 0,
      enabled: !!key,
      onError: () => {
        errorView("User hasn't any key");
        removeTokens();
      },
    }
  );

export const useDeleteComment = (id?: any) =>
  useMutation<any, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.delete(`/comments/${id}`);
    return response?.data?.data;
  }, id);

  export const useDeleteDocumentById = (key?: any) =>
    useMutation<any, ApiServiceErr, any>(async (key) => {
      const response = await axiosApi.delete(
        `/upload-documents/delete?key=${key}`
      );
      return response?.data?.data;
    }, key);
