import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";
import { errorView } from "../helpers/ErrorHandler";

export const useCultivationFormData = (opt?: MutOptions<any>) => {
  return useMutation<string, ApiServiceErr, any>(async (formData) => {
    const response = await axiosApi.post(`/farm-gate`, formData);
    return response?.data;
  }, opt);
};

export const useUpdateCultivationFormData = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (formData: any) => {
    return await axiosApi.put(`/farm-gate/${formData.farmGateId}`, formData);
  }, opt);
};

export const useCultivationFormFieldData = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (data: any) => {
    return await axiosApi.post(
      `/farm-gate/cultivation-field/${data.farmGateId}`,
      data
    );
  }, opt);
};

export const useGetCultivationFormData = (farmGateId: any) => {
  return useQuery(
    ["getFarmingData", farmGateId],
    async () => {
      const response = await axiosApi.get(`/farm-gate/${farmGateId}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!farmGateId,
      onError: () => {
        errorView("Something went wrong");
      },
    }
  );
};

export const useDeleteFarmGate = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (farmGateId) => {
    return await axiosApi.delete(`/farm-gate/${farmGateId}`);
  }, opt);
};
