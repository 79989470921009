import React from "react";
import { AccordionTitleView } from "../../../../components/accordionTitleView/AccordionTitleView";
import { Grid, Container } from "semantic-ui-react";
import PackagingMachineryTable from "./packagingMachineryTable";
import "./packagingMachinery.scss";

interface PackagingMachineryProps {
  title: string;
  isSimiulationDrawing?:boolean
  packagingData?: PackagingData;
  newPackagingData?: PackagingData;
  setNewPackagingData: React.Dispatch<React.SetStateAction<PackagingData | undefined>>;
  packagingLevels: PackagingLevels[];
  wasteManagements: WasteManagements[];
  isView?: boolean;
  createPackagingMethod?: (data: any) => void;
  updatePackagingMethod?: (data: any) => void;
  deletePackagingMethod?: (data: any) => void;
}

const PackagingMachinery: React.FC<PackagingMachineryProps> = ({
  title,
  isSimiulationDrawing,
  packagingData,
  newPackagingData,
  setNewPackagingData,
  packagingLevels,
  wasteManagements,
  isView,
  createPackagingMethod,
  updatePackagingMethod,
  deletePackagingMethod,
}) => {

  if (!packagingData && !isSimiulationDrawing) return null;

  const packagingMachinery = packagingData?.packagingMethod || [];
  const newPackaging = newPackagingData?.packagingMethod || [];

  return (
    <div>
      <AccordionTitleView
        accordionEnabled={false}
        title={title}
        plusIcon={false}
      />
      <Container fluid>
        <Grid>
          <Grid.Row divided>
            <Grid.Column className="packaging-machinery-view" computer={8} tablet={8} mobile={8}>
              <PackagingMachineryTable
                tableData={packagingMachinery}
                packagingLevels={packagingLevels}
                wasteManagements={wasteManagements}
              />
            </Grid.Column>
            <Grid.Column className="packaging-machinery-view-edit" computer={8} tablet={8} mobile={8}>
              <PackagingMachineryTable
                originalData={packagingMachinery}
                tableData={newPackaging}
                editable
                setNewPackagingData={setNewPackagingData}
                packagingLevels={packagingLevels}
                wasteManagements={wasteManagements}
                isView={isView}
                createPackagingMethod={createPackagingMethod}
                updatePackagingMethod={updatePackagingMethod}
                deletePackagingMethod={deletePackagingMethod}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default PackagingMachinery;
