import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  Accordion,
  AccordionTitle,
  AccordionContent,
  Grid,
  Table,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline, MdArrowCircleDown } from "react-icons/md";

import {
  AccordionTitleView,
  CommonTable,
  DocumentUploader,
  InputTextArea,
} from "../../components";
import "./noteDocument.scss";
import { NoteDocumentTable } from "../../config/constants";
import { getTenantId } from "../../utils/cacheStorage";
import {
  useDeleteComment,
  useDeleteDocumentById,
  useGetCommentDataById,
  useGetDocumentUrlByKey,
  usePatchCommentData,
  usePostCommentData,
  useUploadFileDocument,
} from "../../api/noteDocument/noteDocument";
import { errorView, successMessage } from "../../helpers/ErrorHandler";

// Types
interface NoteAndDocumentProps {
  isDisabled?: boolean;
  parentId?: string | null;
}

const NoteAndDocument = forwardRef(
  ({ isDisabled = true, parentId = null }: NoteAndDocumentProps, ref) => {
    const [accordionEnabled, setAccordionEnabled] = useState<boolean>(true);
    const [internalNote, setInternalNote] = useState<boolean>(true);
    const [documentFile, setDocumentFile] = useState<any[]>([]);
    const [downloadKey, setDownloadKey] = useState<any>(null);

    const { data: commentData, refetch: getComment } =
      useGetCommentDataById(parentId);
    const { mutate: createComment } = usePostCommentData();
    const { mutate: updateComment } = usePatchCommentData();
    const { mutate: uploadFileData } = useUploadFileDocument();
    const { mutate: deleteComment } = useDeleteComment();
    const { mutate: deleteDocumentsById } = useDeleteDocumentById();

    const {
      data: currentDocumentData,
      status: documentUrlStatus,
      fetchStatus: documentUrlFetchStatus,
    } = useGetDocumentUrlByKey(downloadKey);

    useEffect(() => {
      if (downloadKey) {
        window.open(currentDocumentData?.signedUrl);
      }
      setDownloadKey(null);
    }, [currentDocumentData]);

    const values = useMemo(() => {
      setDocumentFile(commentData?.supportingDocuments || []);
      return {
        internalNotes: commentData?.internalNotes,
        reviewingNotes: commentData?.reviewingNotes,
      };
    }, [commentData]);

    const {
      register,
      formState: { errors },
      handleSubmit,
      getValues,
    } = useForm({
      mode: "all",
      defaultValues: values,
      values: values,
    });

    // Handlers
    const handleAccordionClick = () => {
      setAccordionEnabled((prev) => !prev);
    };

    const handleFileOpen = (file: any) => {
      if (file?.isNew) {
        const url = URL.createObjectURL(file.file as any);
        return window.open(url);
      }
      setDownloadKey(file.key);
    };

    const updateCurrentDocument = (index: any) => {
      const updatedFiles = [...documentFile];
      updatedFiles.splice(index, 1);
      setDocumentFile(updatedFiles);
    };

    const handleRemoveDocument = (index: number) => {
      const deleteFile = documentFile[index];
      if (deleteFile.key) {
        deleteDocumentsById(deleteFile.key, {
          onSuccess() {
            return updateCurrentDocument(index);
          },
        });
      }
      updateCurrentDocument(index);
    };

    const updateCurrentFileData = (
      uploadedFiles: any[],
      commentId?: string
    ): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (commentData?._id || commentId) {
          updateComment(
            {
              id: commentData?._id || commentId,
              data: {
                supportingDocuments: uploadedFiles,
              },
            },
            {
              onSuccess: () => {
                successMessage("Comment updated successfully");
                resolve();
              },
              onError: (error) => {
                reject(error);
              },
            }
          );
        } else {
          resolve();
        }
      });
    };

    const uploadDocument = (parentId: string): Promise<any[]> => {
      return new Promise(async (resolve, reject) => {
        if (!documentFile.length) {
          resolve([]); // Resolve immediately if there are no files to upload
          return;
        }

        let currentUploadedFile = documentFile.filter((e) => e.url);
        let needUpload = documentFile.filter((e) => e.isNew);

        const uploadedData = [];
        for (const file of needUpload) {
          //when first time create file that file haven't parentId
          if (!file.parentId) {
            file.parentId = parentId;
          }
          const uploadedFile = await new Promise((resolve, reject) => {
            uploadFileData(file, {
              onSuccess: (data) =>
                resolve({
                  fileName: file.file.name,
                  fileType: file.file.type,
                  uploadOn: file.uploadOn,
                  url: data.url,
                  key: data.Key,
                }),
              onError: reject,
            });
          });
          uploadedData.push(uploadedFile);
        }
        try {
          resolve([...uploadedData, ...currentUploadedFile]);
        } catch (error) {
          reject(error); // Reject if any upload fails
        }
      });
    };

    const handleNoteUpdate = async (parentId: any): Promise<void> => {
      const data = getValues();
      let noteDetails: any = {
        id: commentData?._id || null,
        data: {
          ...data,
          tenantId: getTenantId(),
          parentId,
          supportingDocuments: [],
        },
      };

      if (commentData) {
        return new Promise((resolve, reject) => {
          updateComment(noteDetails, {
            onSuccess: async (data) => {
              successMessage("Comment updated successfully");
              let currentUploadData = await uploadDocument(parentId);
              updateCurrentFileData(currentUploadData);
              resolve();
            },
            onError: (error) => {
              reject(error);
            },
          });
        });
      } else {
        return new Promise(async (resolve, reject) => {
          let currentUploadData = await uploadDocument(parentId);
          noteDetails.data.supportingDocuments = currentUploadData;
          createComment(noteDetails, {
            onSuccess: async (data) => {
              successMessage("Comment created successfully");
              resolve();
            },
            onError: (error) => {
              reject(error);
            },
          });
        });
      }
    };

    const deleteCommentById = async (id: any) => {
      deleteComment(id, {
        onSuccess(data, variables, context) {
          errorView("Delete comment successfully");
        },
      });
    };

    useImperativeHandle(ref, () => ({
      async saveDocumentAndNote(parentId: any): Promise<void> {
        return new Promise(async (resolve, reject) => {
          try {
            await handleNoteUpdate(parentId);
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      },
      async deleteComment(): Promise<void> {
        return new Promise(async (resolve, reject) => {
          try {
            await deleteCommentById(commentData?._id);
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      },
    }));

    const renderTableContent = () =>
      documentFile.map((file: any, index: any) => (
        <Table.Row key={index}>
          <Table.Cell>
            <Icon size="large" name="file pdf outline" />
          </Table.Cell>
          <Table.Cell>{file?.file?.name || file.fileName}</Table.Cell>
          <Table.Cell>{file.uploadOn}</Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                key={index}
                className="alignTableIcon"
              >
                <MdArrowCircleDown
                  cursor="pointer"
                  onClick={() => handleFileOpen(file)}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                key={index}
                className="alignTableIcon"
              >
                <MdDeleteOutline
                  cursor="pointer"
                  onClick={() => handleRemoveDocument(index)}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      ));

    const renderCustomView = () => (
      <Grid.Row className="pt-0">
        <Grid.Column>
          <InputTextArea
            register={register}
            defaultValues={
              internalNote
                ? getValues().internalNotes
                : getValues().reviewingNotes
            }
            errors={errors.internalNotes}
            customText="customNoteDocumentText"
            placeholder={
              internalNote
                ? "Add personal notes for the team"
                : "Add notes for the reviewing process"
            }
            name={internalNote ? "internalNotes" : "reviewingNotes"}
          />
        </Grid.Column>
      </Grid.Row>
    );

    //handle loading
    if (
      documentUrlStatus == "loading" &&
      documentUrlFetchStatus == "fetching"
    ) {
      return (
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      );
    }

    return (
      <Accordion>
        <AccordionTitle
          active={accordionEnabled}
          onClick={handleAccordionClick}
        >
          <AccordionTitleView
            isDisabled={isDisabled}
            accordionEnabled={accordionEnabled}
            title="Notes and Supporting Documentation"
          />
        </AccordionTitle>
        <AccordionContent active={accordionEnabled}>
          <form onSubmit={handleSubmit(handleNoteUpdate)}>
            <Grid className="noteDocumentMainView">
              <Grid.Row>
                <Grid.Column computer={5}>
                  <button
                    onClick={() => setInternalNote(true)}
                    className={
                      internalNote
                        ? "customNoteButton"
                        : "customNoteButtonOutLine"
                    }
                    type="button"
                  >
                    Add internal notes for the team
                  </button>
                </Grid.Column>
                <Grid.Column computer={6}>
                  <button
                    onClick={() => setInternalNote(false)}
                    className={
                      internalNote
                        ? "customNoteButtonOutLine"
                        : "customNoteButton"
                    }
                    type="button"
                  >
                    Add notes for the reviewing process
                  </button>
                </Grid.Column>
                <Grid.Column computer={5}>
                  <DocumentUploader
                    buttonTitle="Upload Documentation"
                    customImageViewMain="customNoteImageView"
                    maxFileSize={1}
                    imageData={(data: any) => {
                      const newFile = {
                        file: data,
                        parentId: parentId || null,
                        uploadOn: new Date().toDateString(),
                        isNew: true,
                      };
                      setDocumentFile((documentFile: any) => [
                        ...documentFile,
                        newFile,
                      ]);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              {renderCustomView()}
              {documentFile?.length > 0 && (
                <Grid.Row>
                  <Grid.Column>
                    <CommonTable tableHeaderData={NoteDocumentTable}>
                      {renderTableContent()}
                    </CommonTable>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </form>
        </AccordionContent>
      </Accordion>
    );
  }
);

export default NoteAndDocument;
