import { errorView } from "./ErrorHandler";

export enum AccordionNames {
  CIP_SIP = "CIP/SIP",
  CHEMICALS = "chemicals",
  WASTE_MANAGEMENT = "waste/side streams",
  RECIPE = "recipe",
}

export const validateAccordionOpen = (
  activeStatus: boolean,
  activeAccordion: AccordionNames | null,
  requestAccordion: AccordionNames
): boolean => {
  if (activeStatus) {
    errorView(
      `Please close or save the ${activeAccordion} details before opening ${requestAccordion}.`
    );
    return false;
  }
  return true;
};

export const validateAccordionSubmit = (
  activeAccordion: AccordionNames | null
): boolean => {
  if (activeAccordion) {
    errorView(
      `Please close or save the ${activeAccordion} details before updating the process.`
    );
    return false;
  }
  return true;
};
