import { useMemo, useState } from "react";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { CommonModal } from "../../../components";
import { useUpdateSupplierDetails } from "../../../api/supplier";
import SupplierRegisterForm from "../supplierRegisterForm/SuppllierRegisterForm";
import "./supplierEdit.scss";
import { THEME } from "../../../config/constants";

const SupplierEditModal = ({
  visibleModal,
  closeModal,
  onSuccessEdit,
  supplier,
}: any) => {
  const [isSupplierAsUser, setIsSupplierAsUser] = useState<boolean>(false);
  const [country, setCountry] = useState<number>(1);

  const values = useMemo(() => {
    setIsSupplierAsUser(supplier?.is_supplier_as_user || false);
    setCountry(supplier?.supplier_country || 1)
    if (supplier?.supplier_contact_name) {
      return supplier;
    } else {
      return {
        ...supplier,
        supplier_contact_name: "",
      };
    }
  }, [supplier]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: values,
    values: values,
  });

  const { mutate: onUpdateSupplier, isLoading: isLoadingSupplierUpdate } =
    useUpdateSupplierDetails({
      onError: () => {
        closeModal();
      },
      onSuccess: () => {
        closeModal();
        onSuccessEdit();
        reset();
      },
    });

  const onSubmit = (data: any) => {
    data.supplier_country = country
    data.is_supplier_as_user = isSupplierAsUser;
    data.theme = THEME
    delete data.supplierIngredientDetails;
    delete data.products;
    delete data.ingredients;
    onUpdateSupplier(data);
  };

  // Loading state check
  if (isLoadingSupplierUpdate) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => closeModal()}
      centered={true}
      
      title="Supplier Details Edit"
    >
      <SupplierRegisterForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        setCountry={setCountry}
        country={country}
        setIsSupplierAsUser={setIsSupplierAsUser}
        isSupplierAsUser={isSupplierAsUser}
      />
    </CommonModal>
  );
};

export default SupplierEditModal;
