import React, { useEffect, useState } from "react";
import { AccordionTitleView } from "../../../../components/accordionTitleView/AccordionTitleView";
import { Grid, Container } from "semantic-ui-react";
import { InputText } from "../../../../components/InputText/InputText";
import { useForm } from "react-hook-form";
import PackagingTable from "./packagingTable";
import { calculateTotalWeight } from "../../utils/calculateTotalWeight";

interface PackagingProps {
    isSimiulationDrawing?: boolean
    packagingData?: PackagingData;
    packagingType: PackagingType;
    newPackagingData?: PackagingData;
    setNewPackagingData: React.Dispatch<React.SetStateAction<PackagingData | undefined>>;
    packagingMaterials: PackagingMaterial[];
    isView?: boolean;
    createPackaging?: (data: PackagingData) => void;
    updatePackaging?: (data: PackagingData) => void;
    deletePackaging?: (data: PackagingData) => void;
    updatePackingDetails?: (data: any) => void;
}

interface PackagingValues {
    title: string;
    label: string;
    name: string;
    field: PackagingField;
    type: string;
    totalWeightField: TotalWeightField;
    unitSizeField: UnitSizeField;
}

const PACKAGING_VALUES: Record<PackagingType, PackagingValues> = {
    "base-unit": {
        title: "Base unit packaging",
        label: "Unit size (kg)",
        name: "baseUnit",
        field: "singleUnitPackaging",
        type: "Base unit",
        totalWeightField: "totalWeightBaseUnit",
        unitSizeField: "unitSize",
    },
    "sales-unit": {
        title: "Pack or inner pack packaging",
        label: "Number of Base Units",
        name: "numBaseUnit",
        field: "salesUnitPackaging",
        type: "Pack",
        totalWeightField: "totalWeightSalesOfPack",
        unitSizeField: "numberOfBaseUnits",
    },
    "bulk-unit": {
        title: "Case packaging",
        label: "Number of Packs",
        name: "numPacks",
        field: "bulkUnitPackaging",
        type: "Case",
        totalWeightField: "totalWeightBulkPack",
        unitSizeField: "numberOfPacks",
    },
    "transport-unit": {
        title: "Pallet packaging",
        label: "Number of Cases",
        name: "numCases",
        field: "transportUnitPackaging",
        type: "Pallet",
        totalWeightField: "totalWeightTransportUnitPack",
        unitSizeField: "numberOfCases",
    },
};

const Packaging: React.FC<PackagingProps> = ({
    packagingData,
    isSimiulationDrawing,
    packagingType,
    newPackagingData,
    setNewPackagingData,
    packagingMaterials,
    isView,
    createPackaging,
    updatePackaging,
    updatePackingDetails,
    deletePackaging,
}) => {
    const [packageUnitValue, setPackageUnitValue] = useState<string | number>(newPackagingData?.[PACKAGING_VALUES[packagingType].unitSizeField] || 0);
    const [palletSizeWidth, setPalletSizeWidth] = useState<string | number>(newPackagingData?.palletWidth || 0);
    const [palletSizeLength, setPalletSizeLength] = useState<string | number>(newPackagingData?.palletLength || 0);

    const {
        register,
        formState: { errors },
        setValue,
        control,
    } = useForm();

    useEffect(() => {
        if (newPackagingData) {
            setValue(PACKAGING_VALUES[packagingType].name, newPackagingData[PACKAGING_VALUES[packagingType].unitSizeField] || 0);
            if (packagingType === "transport-unit") {
                setValue("palletWidth", newPackagingData.palletWidth || 0);
                setValue("palletLength", newPackagingData.palletLength || 0);
            }
        }
    }, [newPackagingData, packagingType]);

    useEffect(() => {
        if (newPackagingData) {
            const updatedData = {
                ...newPackagingData,
                [PACKAGING_VALUES[packagingType].unitSizeField]: packageUnitValue,
                palletWidth: Number(palletSizeWidth),
                palletLength: Number(palletSizeLength),
            };
            setNewPackagingData(calculateTotalWeight(updatedData));
        }
    }, [packageUnitValue, palletSizeWidth, palletSizeLength]);
    if (!packagingData && !isSimiulationDrawing) return null

    const { title, label, name, field, type, totalWeightField, unitSizeField } = PACKAGING_VALUES[packagingType];
    const unitSize = packagingData?.[unitSizeField] || 0;
    const packaging = (packagingData?.[field] as unknown as PackagingTableData[]) || [];
    const newPackaging = (newPackagingData?.[field] as unknown as PackagingTableData[]) || [];
    const totalWeightOfItem = packagingData?.[totalWeightField];

    const handleInputChange =
        (setter: React.Dispatch<React.SetStateAction<string | number>>, name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value === "" ? "0" : Number(e.target.value);
            setter(value);
            if (updatePackingDetails && newPackagingData) {
                const updatedData: any = {};
                updatedData[name] = value;
                updatePackingDetails(calculateTotalWeight(updatedData));
            }
        };

    const renderPalletFields = () => (
        <>
            <Grid.Column floated="left" computer={3} tablet={8} mobile={16}>
                <InputText
                    errors={errors.palletWidth}
                    register={register}
                    labelName="Pallet width"
                    placeholder={packaging?.length > 0 ? packagingData?.palletWidth : "width (cm)"}
                    name="palletWidth"
                    type="number"
                    minNumber={0}
                    onChangeFunction={handleInputChange(setPalletSizeWidth, "palletWidth")}
                    control={control}
                />
            </Grid.Column>
            <Grid.Column floated="left" computer={3} tablet={8} mobile={16}>
                <label className="textLabelBlind">+</label>
                <InputText
                    errors={errors.palletLength}
                    register={register}
                    labelName="Pallet length"
                    placeholder={packaging?.length > 0 ? packagingData?.palletLength : "length (cm)"}
                    name="palletLength"
                    type="number"
                    minNumber={0}
                    onChangeFunction={handleInputChange(setPalletSizeLength, "palletLength")}
                    control={control}
                />
            </Grid.Column>
        </>
    );

    return (
        <div>
            <AccordionTitleView accordionEnabled={false} title={title} plusIcon={false} />
            <Container fluid>
                <Grid>
                    <Grid.Row divided>
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <Grid>
                                <Grid.Column floated="left" computer={5} tablet={8} mobile={16}>
                                    <label className="textLabel">{label}</label>
                                    <br />
                                    <label className="textLabel">{unitSize}</label>
                                </Grid.Column>
                                <Grid.Column floated="right" computer={5} tablet={8} mobile={16}>
                                    <label className="textLabel">{`Total weight of ${type}`}</label>
                                    <br />
                                    <label className="textLabel">{totalWeightOfItem}</label>
                                </Grid.Column>
                            </Grid>
                            <PackagingTable tableData={packaging} packagingMaterials={packagingMaterials} />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <Grid>
                                <Grid.Column floated="left" computer={4} tablet={8} mobile={8}>
                                    <InputText
                                        id={name}
                                        register={register}
                                        labelName={label}
                                        type="number"
                                        placeholder={packaging?.length ? unitSize.toString() : "0"}
                                        name={name}
                                        minNumber={0}
                                        onChangeFunction={handleInputChange(setPackageUnitValue, unitSizeField)}
                                        control={control}
                                    />
                                </Grid.Column>
                                {packagingType === "transport-unit" ? renderPalletFields() : null}
                                <Grid.Column floated={packagingType !== "transport-unit" ? "right" : undefined} computer={5} tablet={8} mobile={16}>
                                    <label className="textLabel">{`Total weight of ${type}`}</label>
                                    <br />
                                    <label className="textLabel">{newPackagingData?.[totalWeightField]}</label>
                                </Grid.Column>
                            </Grid>
                            <PackagingTable
                                originalData={packaging}
                                tableData={newPackaging}
                                editable
                                setNewPackagingData={setNewPackagingData}
                                field={field}
                                packagingMaterials={packagingMaterials}
                                isView={isView}
                                createPackaging={createPackaging}
                                updatePackaging={updatePackaging}
                                deletePackaging={deletePackaging}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    );
};

export default Packaging;
